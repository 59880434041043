import { jsx as _jsx } from "react/jsx-runtime";
import { CableIcon, ComputerMouseIcon, DefaultIcon, DeskBellIcon, DishwasherIcon, DishwasherRegularIcon, FanIcon, FridgeIcon, HairDryerIcon, HeadphonesIcon, KickScooterIcon, LaptopMacIcon, LightBulbLinearIcon, MicrowaveIcon, PhoneIcon, ScooterIcon, StoveIcon, TvIcon, VacuumCleanerIcon, WashingMachineIcon, } from "./icons";
export var Catalog = function (_a) {
    var _b = _a.iconKey, iconKey = _b === void 0 ? "default" : _b;
    var getCurrentIcon = function (value) {
        switch (value) {
            case "default": {
                return _jsx(DefaultIcon, {});
            }
            case "fridge": {
                return _jsx(FridgeIcon, {});
            }
            case "phone": {
                return _jsx(PhoneIcon, {});
            }
            case "stove": {
                return _jsx(StoveIcon, {});
            }
            case "dishwasherRegular": {
                return _jsx(DishwasherRegularIcon, {});
            }
            case "computerMouse": {
                return _jsx(ComputerMouseIcon, {});
            }
            case "laptopMac": {
                return _jsx(LaptopMacIcon, {});
            }
            case "hairDryer": {
                return _jsx(HairDryerIcon, {});
            }
            case "deskBell": {
                return _jsx(DeskBellIcon, {});
            }
            case "tv": {
                return _jsx(TvIcon, {});
            }
            case "microwave": {
                return _jsx(MicrowaveIcon, {});
            }
            case "fan": {
                return _jsx(FanIcon, {});
            }
            case "cable": {
                return _jsx(CableIcon, {});
            }
            case "washingMachine": {
                return _jsx(WashingMachineIcon, {});
            }
            case "vacuumCleaner": {
                return _jsx(VacuumCleanerIcon, {});
            }
            case "headphones": {
                return _jsx(HeadphonesIcon, {});
            }
            case "lightBulbLinear": {
                return _jsx(LightBulbLinearIcon, {});
            }
            case "scooter": {
                return _jsx(ScooterIcon, {});
            }
            case "kickScooter": {
                return _jsx(KickScooterIcon, {});
            }
            case "dishwasher": {
                return _jsx(DishwasherIcon, {});
            }
            default: {
                return _jsx(DefaultIcon, {});
            }
        }
    };
    return getCurrentIcon(iconKey);
};
